import React, { useEffect, useState } from 'react'
import { Grid, Tooltip, Box } from '@mui/material'
import PrimaryKpiCard, {
    type KpiCardType,
    MockedPrimaryCard,
} from '../../../../../components/features/PrimaryKpiCard'
import { useAppSelector } from '../../../../../redux/hooks'
import { Units } from '../../../../../redux/global/globalTypes'
import {
    EnvName,
    FsFFRKPIs,
    FsTGMKPIs,
} from '../../../../../redux/user/userTypes'

interface KpiData extends KpiCardType {
    id: number
    description?: string
}

export interface ConfigKPIs {
    id: number
    name: string
    units: Units
    description: string
    number: number
    difference: number
    route: string
    evolution: number
}

const FsKpis = ({
    KPIsConfig,
}: {
    KPIsConfig: ({
        KPIs,
        envName,
        unit
    }: {
        KPIs: FsTGMKPIs | FsFFRKPIs
        envName: EnvName
        unit?: Units
    }) => ConfigKPIs[]
}) => {
    const [enhancedKpis, setKpis] = useState<KpiData[] | null>(null)
    const { unit } = useAppSelector((state) => state.global)
    const { KPIs, env } = useAppSelector((state) => state.global)

    const unitText =
        unit === Units.kg
            ? `en kilogramme (${unit})`
            : `en Unité de Vente Consommateur (${unit})`

    useEffect(() => {
        if (KPIs) {
            setKpis(
                KPIsConfig({
                    KPIs: KPIs,
                    envName: env.name,
                    unit: unit
                })
            )
        }
    }, [KPIs, unit, unitText])

    const kpisCounter = enhancedKpis && enhancedKpis.length

    return (
        <Grid container justifyContent="space-between">
            {enhancedKpis
                ? enhancedKpis.map((kpi) => (
                    <Tooltip key={kpi.id} title={kpi.description}>
                        <Box
                            sx={{
                                flex: 1,
                                maxWidth:
                                    kpisCounter === 4
                                        ? '24%'
                                        : kpisCounter === 2
                                            ? '49.5%'
                                            : '32.5%',
                            }}
                        >
                            <PrimaryKpiCard
                                difference={kpi.difference}
                                name={kpi.name}
                                number={kpi.number}
                                units={kpi.units}
                                route={kpi.route}
                                evolution={kpi.evolution}
                            />
                        </Box>
                    </Tooltip>
                ))
                :
                Array(kpisCounter)
                    .fill('a')
                    .map((item, index) => (
                        <MockedPrimaryCard key={`${item}-${index}`} />
                    ))}
        </Grid>
    )
}

export default FsKpis
