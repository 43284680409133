import { Chip, Grid, Tooltip, Typography } from '@mui/material'
import React from 'react'
import NumberWithSpace from '../../../../../../components/ui/NumberWithSpace'
import {
    BLACK,
    BRIC,
    LIGHT_MOCK,
    SECONDARY,
} from '../../../../../../components/ui/Colors'
import { Annotation, PrimaryText } from '../../../../../../components/ui/Text'
import { type RetailProduct } from '../../../../../../redux/user/userTypes'
import { Units } from '../../../../../../redux/global/globalTypes'

interface OwnProps {
    data: RetailProduct
    isTop: boolean
    border?: boolean
}

interface InfoListProps {
    evolution: number
    vmm: number
    stores: number
    isTop: boolean
}

interface InfoListItemProps {
    title: string
    data: number
    unit: string
    tooltip: string
}

const GREEN = '#809861'
const RED = '#E36F20'

const ChipIndicator = ({ isTop }: { isTop: boolean }) => {
    return (
        <Chip
            label={isTop ? 'MAX' : 'MIN'}
            sx={{
                background: isTop ? SECONDARY : BRIC,
                width: '2.6vw',
                height: '1.6vh',
                fontSize: '0.9vmin',
                mb: '1vh',
                '& span': {
                    paddingLeft: '0.5vw',
                    paddingRight: '0.5vw',
                },
            }}
        />
    )
}

const InformationListItem = ({
    title,
    data,
    unit,
    tooltip,
}: InfoListItemProps) => {
    return (
        <Tooltip title={tooltip}>
            <Grid item>
                <Annotation color={BLACK}>{title}</Annotation>
                <Annotation color={BLACK} bold>
                    {data === 0 ? (
                        <span>-</span>
                    ) : (
                        <NumberWithSpace number={data} />
                    )}
                    {data !== 0 && unit}
                </Annotation>
            </Grid>
        </Tooltip>
    )
}

const InformationsList = ({ evolution, vmm, stores, isTop }: InfoListProps) => {
    return (
        <Grid container direction="column">
            <InformationListItem
                title="Croissance en %"
                data={Math.round(evolution)}
                unit={Units.percent}
                tooltip={
                    isTop
                        ? 'Taux de croissance correspondant à votre TOP référence.'
                        : 'Taux de croissance correspondant à votre FLOP référence.'
                }
            />
            <InformationListItem
                title="VMM"
                data={Math.round(vmm)}
                unit={Units.uvc}
                tooltip="Volume Mensuel Moyen vendu de cette référence."
            />
            <InformationListItem
                title="Points de vente"
                data={stores}
                unit={Units.stores}
                tooltip="Nombre de points de vente vendeurs de cette référence sur la période A."
            />
        </Grid>
    )
}

const LineChartTitle = ({ title }: { title: string }) => {
    if (title.length > 28) {
        return (
            <Tooltip title={title}>
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        color: BLACK,
                        whiteSpace: 'nowrap',
                        maxWidth: '90%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '1.6vmin',
                    }}
                >
                    {title}
                </Typography>
            </Tooltip>
        )
    }

    return (
        <PrimaryText
            bold
            color={BLACK}
            whiteSpace="nowrap"
            maxWidth="90%"
            overflow="hidden"
            textOverflow="ellipsis"
        >
            {title}
        </PrimaryText>
    )
}

const LineChartCard = ({ data, isTop, border = true }: OwnProps) => {
    const title = isTop
        ? 'Croissance maximale en UVC correspondant à votre TOP référence par rapport à A-1.'
        : 'Recul maximal en UVC correspondant à votre FLOP référence par rapport à A-1.'

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            wrap="nowrap"
            sx={{
                borderRight: border ? `1px solid ${LIGHT_MOCK}` : 'none',
                height: '50%',
            }}
        >
            <Grid
                container
                direction="column"
                flex={2}
                sx={{ maxWidth: '66%', pl: '0.5vw' }}
            >
                {border && <ChipIndicator isTop={isTop} />}
                <LineChartTitle title={data.name} />
                <Tooltip title={title}>
                    <Typography
                        sx={{
                            fontSize: '2.8vmin',
                            color: data.evolution > 0 ? GREEN : RED,
                            fontWeight: 'bold',
                        }}
                    >
                        {data.evolution > 0 ? '+ ' : null}
                        <NumberWithSpace number={Math.round(data.difference)} />
                        {Units.uvc}
                    </Typography>
                </Tooltip>
            </Grid>
            <Grid item flex={1}>
                <InformationsList
                    evolution={data.evolution}
                    vmm={data.vmm}
                    stores={data.stores}
                    isTop={isTop}
                />
            </Grid>
        </Grid>
    )
}

export default LineChartCard
