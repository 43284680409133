import React from "react"
import { KpiText, PrimaryText } from "../ui/Text"
import Tendency from "../ui/Tendency"
import { Units } from "../../redux/global/globalTypes"
import { Grid } from "@mui/material"
import { BLACK, BRIC, GREEN } from "../ui/Colors"
import NumberWithSpace from "../ui/NumberWithSpace"
import { roundIfNotZero } from "../../helpers/roundIfNotZero"

// Icons
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined'
import RestaurantIcon from '@mui/icons-material/Restaurant';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

export enum KpiIcon {
    UF = "clients",
    VOLUME = "volume",
    PROMO = "promo"
}

const getIcon = (icon: KpiIcon): JSX.Element => {
    switch (icon) {
        case KpiIcon.UF:
            return <RestaurantIcon sx={{ color: "#6993FF", fontSize: "6vmin", mb: "1vh" }} />
        case KpiIcon.VOLUME:
            return <ShoppingBagOutlinedIcon sx={{ color: "#6993FF", fontSize: "6vmin", mb: "1vh" }} />
        case KpiIcon.PROMO:
            return <DiscountOutlinedIcon sx={{ color: "#6993FF", fontSize: "6vmin", mb: "1vh" }} />
        default:
            return <></>
    }
}

export const NumberCard = ({ number, difference, evol, unit, icon, text, kpi = false }: { number: number, difference?: number, evol?: number, unit: Units, icon?: KpiIcon, text?: string, kpi?: boolean }) => {

    const iconToDisplay = getIcon(icon)
    return (
        <Grid container justifyContent="center" direction="column" alignItems="center">
            {iconToDisplay}
            <PrimaryText color={BLACK} >
                {text}
            </PrimaryText>
            <KpiText >
                <NumberWithSpace number={roundIfNotZero(number, false)} />
                {`${unit}`}
            </KpiText >
            <Grid sx={{ fontSize: "4vmin", mb: "1vh" }}>
                {(difference || difference === 0) && <Tendency
                    difference={difference}
                    unit={unit}
                    evolution={evol}
                    kpi={kpi}
                />}
            </Grid>
        </Grid>

    )
}

export const StockageNumber = ({ number, text, unit }: { number: number, text: string, unit: Units }) => {
    const color = number >= 0 ? BRIC : GREEN
    const positifOrNegatif = number > 0 ? "+" : ""
    return (
        <Grid container justifyContent="center" direction="column" alignItems="center">
            <PrimaryText color={BLACK} >
                {text}
            </PrimaryText>
            <KpiText color={color}>
                {positifOrNegatif} <NumberWithSpace number={roundIfNotZero(number, false)} />
                {`${unit}`}
            </KpiText >
        </Grid>
    )
}

export const IncrementalNumber = ({ number, text, unit }: { number: number, text: string, unit: Units }) => {

    return (
        <Grid container justifyContent="center" direction="column" alignItems="center" mb="1vh">
            <PrimaryText color={BLACK} >
                {text}
            </PrimaryText>
            <KpiText  >
                <NumberWithSpace number={roundIfNotZero(number, false)} />
                {`${unit}`}
            </KpiText >
        </Grid>
    )
}

export const EvolSellOutNumber = ({ number, text, unit, difference }: { number: number, text: string, unit: Units, difference?: number, }) => {
    const color = number > 0 ? GREEN : BRIC
    const arrowTendency =
        difference > 0 ? (
            <ArrowUpwardIcon sx={{ color }} />
        ) : (
            <ArrowDownwardIcon sx={{ color }} />
        )
    return (
        <Grid container justifyContent="center" direction="column" alignItems="center" mb="1vh" >
            <PrimaryText color={BLACK} >
                {text}
            </PrimaryText>
            <KpiText color={color} >
                {arrowTendency} <NumberWithSpace number={roundIfNotZero(number, false)} />
                {`${unit} (${roundIfNotZero(difference)}%)`}
            </KpiText >
        </Grid>)
}