import React, { useState, useCallback } from 'react'
import { Breadcrumbs, Drawer, Grid, Link, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { SwitchPeriod } from './SwitchPeriod'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { PrimaryButton } from '../../../ui/Button'
import { UsersRole } from '../../../../redux/user/userTypes'
import { ViewConfig, ViewsSteps } from '../../../../redux/views/viewsTypes'
import { setupCampaign } from '../../../../redux/views/viewsSlice'
import { Box } from '@mui/system'
import { PagesName } from '../../navigation/types/pages'
import { WHITE } from '../../../ui/Colors'
import { PrimaryText } from '../../../ui/Text'

const BasicBreadcrumbs = ({ title }: { title: string }) => {
    return (
        <Breadcrumbs>
            <Link
                underline="hover"
                color="inherit"
                href="/multiAccount"
            ><Typography
                sx={{ fontSize: '1.7vw', fontWeight: 500 }}
                ml="1vw"
            >
                    Page d'accueil
                </Typography>
            </Link>
            {title && <Typography
                sx={{ fontSize: '1.7vw', fontWeight: 500 }}
                ml="1vw"
            >
                {title}
            </Typography>}
        </Breadcrumbs>

    );
}

const PrivateHeader = ({
    title,
    attribute,
    period,
    onViewPage,
    page
}: {
    title: string
    attribute?: number
    period?: boolean
    hasOtherUnitAvailable?: boolean
    onViewPage?: boolean
    page?: PagesName
}) => {
    /* FOR SWITCH UNIT
    const dispatch = useAppDispatch()
    const _setUnit = useCallback(
        (unit: units) => dispatch(setUnit(unit)),
        [dispatch]
    )
    const { unit } = useAppSelector((state) => state.global)

    const handleChangeUnitButton = () => {
        if (unit === Units.kg) {
            _setUnit(Units.uvc)
        }
        if (unit === Units.uvc) {
            _setUnit(Units.kg)
        }
    } */

    const dispatch = useAppDispatch()
    const [open, setOpen] = useState(false)
    const { role } = useAppSelector((state) => state.user.user)


    /* **CAMPAIGNS** */

    const _setupCampaign = useCallback(
        (viewConfig: ViewConfig) =>
            dispatch(setupCampaign({ config: viewConfig })),
        [dispatch]
    )

    const handleCampaignClick = () => {
        _setupCampaign({
            steps: [ViewsSteps.CampaignFS]
        })
    }


    /* **NOTIFICATIONS** */

    /* const { env } = useAppSelector((state) => state.global)
    const navigate = useNavigate()
    const { user } = useAppSelector((state) => state.user)
    const [hasDetails, setDetails] = useState<boolean>(false)
    const [tabValue, setValue] = useState(0)
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { notifications } = useAppSelector((state) => state.notifications)
    const _fetchNotifications = useCallback(async () => {
        await dispatch(fetchNotifications())
    }, [dispatch])

    const notificationsArchived =
        notifications &&
        notifications.filter((el) => el.archive.isArchived === true)

    const notificationsNotArchived =
        notifications &&
        notifications.filter((el) => el.archive.isArchived === false)

        useEffect(() => {
        _fetchNotifications()
    }, [])

    const _updateData = useCallback(
        ({
            products,
            productName,
            notificationId,
        }: {
            products: string[]
            productName: string
            notificationId: string
        }) =>
            dispatch(
                updateData({
                    data: {
                        name: `Vue ${productName}`,
                        environment: env.type,
                        distributor: env.name,
                        filters: {
                            clients: [],
                            products,
                            startDate: activeFsFilters.startDate,
                            endDate: activeFsFilters.endDate,
                            regions: [],
                            categories: [],
                        },
                        creationMode: ViewCreationMode.NOTIFICATION,
                        notificationId: notificationId,
                    },
                })
            ),
        [dispatch]
    )

    const _handleCreation = useCallback(
        async () => await dispatch(handleViewCreation()),
        [dispatch]
    )

    const handleChange = () => {
        setValue((value) => (value === 0 ? 1 : 0))
    }

    const handleCreation = (
        productId: string,
        productName: string,
        notificationId: string
    ) => {
        _updateData({
            products: [productId],
            productName: productName,
            notificationId: notificationId,
        })
        _handleCreation().then((view: ResponseViewsCreation) => {
            navigate(
                `/${view.environment}/${view.distributor}/vues/${view.insertedId}`
            )
        })
    }

    const _archiveNotification = useCallback(
        (notificationId: string, notificationUpdate: Partial<Notification>) => {
            dispatch(
                fetchUpdatedNotification(notificationId, notificationUpdate)
            )
        },
        [dispatch]
    )

    const handleArchive = (
        notificationId: string,
        notificationUpdate: Partial<Notification>
    ) => {
        _archiveNotification(notificationId, notificationUpdate)
    } */


    return (
        <Grid
            container
            wrap="nowrap"
            alignItems="center"
            justifyContent="space-between"
            position="sticky"
        >
            <Grid container alignItems="center" direction="row" width="70%">
                {(role === UsersRole.SUPER_ADMIN) ?
                    (<BasicBreadcrumbs title={title} />) : (<Typography
                        sx={{ fontSize: '1.7vw', fontWeight: 500, cursor: "default" }}
                        ml="1vw"
                    >
                        {title}
                    </Typography>)}
                {attribute ? (
                    <Typography
                        sx={{ fontSize: '1.7vw', fontWeight: 500, cursor: "default" }}
                        ml={1}
                    >
                        ({attribute})
                    </Typography>
                ) : null}
                {period && <SwitchPeriod onViewPage={onViewPage} />}
            </Grid>
            {(page === PagesName.PROMO) || (page === PagesName.CAMPAIGN) &&
                <Grid container alignItems="center" mr="1vw" height="90%" width="auto">
                    <PrimaryButton onClick={handleCampaignClick}>
                        <AddIcon sx={{
                            color: WHITE,
                            width: { xs: '22px', md: '2.5vw' },
                            height: { xs: '22px', md: '2.5vh' },
                        }} />
                        <PrimaryText color={WHITE} textTransform="none">Ajouter une campagne</PrimaryText>
                    </PrimaryButton>
                </Grid>}

            {/*    {status != UsersStatus.STANDALONE &&
                env.type !== EnvType.RETAIl && (
                    <Grid
                        position="relative"
                        onClick={() => setOpen(true)}
                        sx={{ cursor: 'pointer', display: 'flex' }}
                    >
                        <AutoAwesomeIcon
                            fontSize="large"
                            sx={{
                                color: '#FFC700',
                                mr: 2,
                                position: 'relative',
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                padding: 0.2,
                                borderRadius: 1,
                                backgroundColor: SECONDARY,
                                ml: 2.3,
                                mt: 2.3,
                            }}
                        >
                            {/* <PrimaryText bold color={PRIMARY}>
                                {notificationsNotArchived &&
                                    notificationsNotArchived.length}
                            </PrimaryText> 
                        </Box>
                    </Grid>
                    */}
            <Drawer
                anchor="right"
                PaperProps={{ sx: { width: '44vw' } }}
                open={open}
                onClose={() => setOpen(false)}
            >
                {/* <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    sx={{ height: '100%' }}
                >
                    <DrawerHeader
                        tabValue={tabValue}
                        notificationsArchived={notificationsArchived}
                        notificationsNotArchived={notificationsNotArchived}
                        handleChange={handleChange}
                        setOpen={setOpen}
                    />
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        p="2vw"
                    >
                        <Grid item>
                            <SubTitle color={BLACK} mb="5vh" textAlign="center">
                                Bientôt disponible sur votre plateforme !
                            </SubTitle>
                            <PrimaryText
                                color={DARK_BLUE}
                                bold
                                style={{ background: SECONDARY }}
                                width="fit-content"
                            >
                                Détectez les risques de déréférencement
                            </PrimaryText>
                            <PrimaryText color={BLACK} mb="3vh">
                                Sur la base de vos données, nous identifions les
                                baisses significatives de vos ventes, celles qui
                                sont inhabituelles, et celles qui sont dues à la
                                perte d'un segment client. Nous vous alertons
                                des risques qui pourraient être causés par un
                                déréférencement, afin que vous puissiez réagir
                                rapidement.
                            </PrimaryText>
                            <PrimaryText
                                color={DARK_BLUE}
                                bold
                                style={{ background: SECONDARY }}
                                width="fit-content"
                            >
                                Identifiez les opportunités d'assortiment
                            </PrimaryText>
                            <PrimaryText color={BLACK} mb="3vh">
                                Si dans un dépôt une référence se vend auprès
                                d'une typologie d'utilisateur final spécifique,
                                nous ne manquerons pas de vous informer que
                                cette même clientèle achète également dans un
                                autre dépôt !
                            </PrimaryText>
                            <PrimaryText
                                color={DARK_BLUE}
                                bold
                                style={{ background: SECONDARY }}
                                width="fit-content"
                            >
                                Rejoignez notre programme de co-construction
                            </PrimaryText>
                            <PrimaryText color={BLACK} mb="5vh">
                                Si vous souhaitez partager avec nous vos cas
                                d'utilisation, nous avons lancé un programme de
                                co-construction qui vous permettra d'accéder en
                                exclusivité à cette fonctionnalité et de
                                participer à son développement.
                                <br />
                                L'inscription et le programme dans son
                                intégralité sont gratuits et mis en place pour
                                répondre à vos besoins !
                            </PrimaryText>
                        </Grid>
                        <Grid container direction="column" alignItems="center">
                            <PrimaryButton
                                style={{ width: '10vw' }}
                                onClick={() => setDetails(true)}
                            >
                                En savoir plus
                            </PrimaryButton>
                            {hasDetails && (
                                <SecondaryText color={BLACK} mt="2vh">
                                    Merci de votre intérêt, nous allons vous
                                    contacter !
                                </SecondaryText>
                            )}
                        </Grid>
                    </Grid>
                </Grid> */}
                {/*     <DrawerContent
                    tabValue={tabValue}
                    notificationsArchived={notificationsArchived}
                    notificationsNotArchived={notificationsNotArchived}
                    handleArchive={handleArchive}
                    handleChange={handleChange}
                    handleViewCreation={handleCreation}
                    archiverId={user._id}
                /> */}
            </Drawer>
        </Grid>
    )
}

export default PrivateHeader
